export enum OccupationType {
	Unspecified,
	Other,
	Permanent,
	PartTime,
	Entrepreneur,
	FixedTerm,
	Pensioner,
	Unemployed,
	Student,
	SickLeave,
	Retired,
	Housewife,
	TemporarilyEmployed,
	EmployedByHours,
	PublicServant,
	Farmer,
	SelfEmployed,
	Trainee,
	Manager,
	Employment,
	OperatingIncome,
	Capital,
	Child,
}
