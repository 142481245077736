import React, { useContext, useEffect, useState } from 'react'
import { formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationInformation } from 'interfaces/i-application-information'
import updateApplicationServices from 'services/updateApplicationServices'
import pnServices from 'services/pnServices'
import getApplicationServices from 'services/getApplicationServices'
import { SigningUrlType } from 'constants/enums/signingUrlTypes'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { MarketingConsentCheckbox } from '../MarketingConsent/MarketingConsent'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './UpdateBankInfo.scss'

export const UpdateBankInfoSe = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>('')

	let formSchema = FormFactory(
		CustomerForm.ApplicationBankInfoForm,
		{
			formName: 'bankInfoForm',
			blockName: 'bankInfoForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateBankInfo = (formValues: any) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
			payload: {
				...loanApplication,
				updateApplicationData: {
					...loanApplication.updateApplicationData,
					bankInfo: {
						...loanApplication.updateApplicationData?.bankInfo,
						directDebitClearingNumber:
							formValues?.directDebitClearingNumber?.value,
						directDebitAccount:
							formValues?.directDebitAccount?.value,
					},
				},
			},
		})
	}
	useEffect(
		() => {
			updateBankInfo(formStateValues)
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const onNext = async () => {
		setLoading(true)
		setError(false)
		
		try {
			printPn()
			if (
				loanApplication.customerConsents?.allowMarketing
			) {
				allowMarketing()
			}
		} catch (error) {
			console.warn(error)
			setErrorMsg(t('ErrorMsg'))
			setError(true)
			setLoading(false)
		}
	}

	const allowMarketing = async () => {
		await updateApplicationServices
			.updateCustomerConsent(loanApplication.customerConsents!, t)
			.then((response) => {
				if (response === 200) {
					console.log('response ', response)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const printPn = async () => {
		setLoading(true)
		try {
			await pnServices
				.printPn(loanApplication.applicationData?.loanNumber!, t)
				.then(async (response) => {
					if (response === 200) {
						loanApplication.setSigningUrls(
							await getApplicationServices
								.GetSigningUrls(
									loanApplication.applicationData
										?.loanNumber!,
									SigningUrlType.ApplicantSigning,
									t
								)
								.then((response) => {
									if (response) {
										dispatchMsp({
											type: MSP_ACTIONS.SET_INDEX,
											payload: {
												index: msp.index + 1,
											},
										})
									}
								})
						)
					}
				})
				.catch((err) => {
					console.warn('Caught error on printPN: ', err)
					setErrorMsg(t('ErrorMsg'))
					setError(true)
				})
		} catch (err) {
			console.warn(err)
			setErrorMsg(t('ErrorMsg'))
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="update-bank-info denmark">
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					<MarketingConsentCheckbox
						allowMarketing={
							loanApplication.applicationData?.customerConsents
								.allowMarketing
						}
					/>
					{error && (
						<div className="error-box-container">
							<ErrorBox message={errorMsg} />
						</div>
					)}
					<div className="update-bank-info-next-button">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => onNext()}>
							{t('Next')}
						</button>
					</div>
				</>
			)}
		</div>
	)
}
