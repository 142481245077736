import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from 'contexts/AppContext'
import { ProductSettingTypes } from 'constants/enums/productTypes'
import { ApplicationType } from 'constants/enums/applicationTypes'

import logo_white from 'img/tf-logos/tfbank21-logo-symbol-white.svg'

import './Footer.scss'

const Footer = () => {
	const { t } = useTranslation()
	const { footerContent, applicationType } = useContext(AppContext)
	const [productType, setProductType] = useState<
		ProductSettingTypes | undefined
	>(undefined)

	useEffect(
		() => {
			const getProductType = () => {
				switch (applicationType) {
					case ApplicationType.Card:
						setProductType(ProductSettingTypes.Card)
						break
					case ApplicationType.Deposit:
						setProductType(ProductSettingTypes.Deposit)
						break
					case ApplicationType.Loan:
						setProductType(ProductSettingTypes.Loan)
						break
					default:
						setProductType(productType)
						break
				}
			}
			getProductType()
		},
		[applicationType] //eslint-disable-line
	)

	return (
		<div id="footer">
			{footerContent && (
				<div className="footer-top-information">
					<div className="logo-container">
						<img
							src={logo_white}
							alt="TF Bank AB"
							className="logo-footer"
						/>
					</div>
					<div className="footer-content">
						<div className="footer-address-information">
							{Object.entries(footerContent.address).map(
								([key, value]) => {
									return (
										<span className={key} key={key}>
											{value}
										</span>
									)
								}
							)}
						</div>
						{footerContent.links && (
							<div className="footer-about-us">
								<h6>TF Bank</h6>
								{Object.entries(footerContent.links).map(
									([key, link]) => {
										return (
											<span key={'about-us-items' + key}>
												<a
													target="_blank"
													rel="noreferrer"
													href={link.link}>
													{t(link.title)}
												</a>
											</span>
										)
									}
								)}
							</div>
						)}
						{footerContent.productSpecific.map((value, key) => {
							if (
								value.productType === productType ||
								value.productType === ProductSettingTypes.All
							) {
								return (
									<div
										key={'footer-customer-support' + key}
										className="footer-customer-support">
										<h6>{t('CustomerSupport')}</h6>
										{footerContent.country === 'fi' && (
											<React.Fragment>
												<span className="footer-span-block">
													<FontAwesomeIcon
														icon="phone-alt"
														className="mr-1"
													/>
													{value.contactInformation
														.phoneNumber +
														' ' +
														`(${t(
															'RegularDepositAccount'
														)})`}
												</span>
												<span className="footer-span-block">
													<FontAwesomeIcon
														icon="phone-alt"
														className="mr-1"
													/>
													{value.contactInformation
														.phoneNumber2 +
														' ' +
														`(${t('RegularLoan')})`}
												</span>
												<span className="footer-span-block">
													<FontAwesomeIcon icon="envelope" />{' '}
													{
														value.contactInformation
															.email
													}
												</span>
											</React.Fragment>
										)}
										{footerContent.country === 'no' && (
											<span className="footer-span-block">
												<FontAwesomeIcon icon="envelope" />{' '}
												{value.contactInformation.email}
											</span>
										)}
										{footerContent.country === 'de' && (
											<span className="footer-span-block">
												<FontAwesomeIcon icon="envelope" />{' '}
												{value.contactInformation.email}
											</span>
										)}
										{(footerContent.country === 'dk' ||
											footerContent.country === 'es' ||
											footerContent.country === 'se') && (
											<React.Fragment>
												<span className="footer-span-block">
													<FontAwesomeIcon icon="phone-alt" />
													{
														value.contactInformation
															.phoneNumber
													}
												</span>
												<span className="footer-span-block">
													<FontAwesomeIcon icon="envelope" />{' '}
													{
														value.contactInformation
															.email
													}
												</span>
											</React.Fragment>
										)}
									</div>
								)
							} else return null
						})}
					</div>
					<div className="footer-copy-right">
						{`© ${new Date().getFullYear()} ` +
							footerContent.copyRight}
					</div>
				</div>
			)}
		</div>
	)
}

export default Footer
