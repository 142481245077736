import React from 'react'
import QRCode from 'react-qr-code'

interface QRCodeBlockProps {
	readonly qrCodeData: string
}

function QRCodeBlock({ qrCodeData }: QRCodeBlockProps) {
	return (
		<div style={{ background: 'white', padding: '16px' }}>
			<QRCode
				value={qrCodeData}
				size={256}
				style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
				viewBox={`0 0 256 256`}
			/>
		</div>
	)
}

export default QRCodeBlock
