import { useContext } from 'react'

import { getQueryValues } from 'utils/loanHelpers'
import { ApplicationType } from 'constants/enums/applicationTypes'
import { CountryTypes } from 'constants/enums/countryTypes'
import { DocumentTypes } from 'constants/enums/documentTypes'
import { UrlStatus } from 'constants/enums/signingUrlTypes'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IThankYouTypes } from 'interfaces/i-thank-you'

export const ThankYouSettingTypes = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const isPs2dSkipConsent = () =>
		loanApplication?.applicationData?.signingUrls?.psD2?.status! ===
			UrlStatus.Created &&
		getQueryValues().status !== 'ok' &&
		getQueryValues().status !== 'failed'

	const ThankYouTypes: IThankYouTypes[] = [
		{
			market: CountryTypes.no,
			thankYouInformation: [
				{
					applicationType: ApplicationType.Card,
					successText: 'ThankYouDescription',
					backButton: {
						show: isPs2dSkipConsent(),
						subtitleText: 'BackToPsd2',
						buttonName: 'Back',
						stepId: 3,
					},
				},
				{
					applicationType: ApplicationType.Loan,
					successText: 'ThankYouDescription',
					backButton: {
						show: isPs2dSkipConsent(),
						subtitleText: 'BackToPsd2',
						buttonName: 'Back',
						stepId: 3,
					},
				},
			],
		},
		{
			market: CountryTypes.es,
			thankYouInformation: [
				{
					applicationType: ApplicationType.Card,
					successText: 'ThankYouDescriptionCard',
				},
				{
					applicationType: ApplicationType.Loan,
					successText: 'ThankYouDescription',
				},
			],
		},
		{
			market: CountryTypes.dk,
			thankYouInformation: [
				{
					applicationType: ApplicationType.Loan,
					successText: 'ThankYouDescription',
					downloadPnButton: {
						buttonName: 'DownloadPN',
						documentType: DocumentTypes.PromissoryNote,
					},
				},
			],
		},
		{
			market: CountryTypes.se,
			thankYouInformation: [
				{
					applicationType: ApplicationType.Loan,
					successText: 'ThankYouDescription',
					downloadPnButton: {
						buttonName: 'DownloadPN',
						documentType: DocumentTypes.PromissoryNote,
					},
				},
			],
		},
	]

	return ThankYouTypes
}
