import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import QRCodeBlock from './qrCodeBlock'
import { TranslationFile } from 'i18n'
import authenticationService from 'services/authServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { logEvent } from 'appinsights/appinsights'
import { getReturnUrl, maskSsn } from 'utils/authHelpers'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

interface QRCodeWrapperProps {
	orderRef: string
	shouldGenerate: boolean
}

const QRCodeWrapper = ({ orderRef, shouldGenerate }: QRCodeWrapperProps) => {
	const [qrCodeData, setQRCodeData] = useState('')
	const { t } = useTranslation([TranslationFile.Translation])
	const [error, setError] = useState<Error>()

	useEffect(() => {
		const fetchData = async () => {
			if (orderRef && shouldGenerate) {
				try {
					const response =
						await authenticationService.bankIdv2GenerateQrCode(
							orderRef,
							t,
						)
					setQRCodeData(response.qrData)
				} catch (error) {
					setError(new Error(t('BankIDCollectFailure')))
				}
			}
		}

		if (shouldGenerate) {
			fetchData() // Fetch immediately
			const intervalId = setInterval(fetchData, 1000) // Then start the interval
			return () => clearInterval(intervalId) // Cleanup
		}
	}, [orderRef, shouldGenerate, t])

	if (shouldGenerate && !qrCodeData) {
		return <CircleSpinner></CircleSpinner>
	}

	return shouldGenerate ? <QRCodeBlock qrCodeData={qrCodeData} /> : <></>
}

const useBankIdAuth = () => {
	const [orderRef, setOrderRef] = useState('')
	const [shouldGenerate, setShouldGenerate] = useState(false)
	const [error, setError] = useState<Error>()
	const { t } = useTranslation()

	const tryCancelBankIdAuth = useCallback(
		async (ref) => {
			try {
				await authenticationService.bankIdv2AuthCancel(ref, t)
			} catch (err) {
				setError(new Error(t('BankIdCancelFailure')))
			}
		},
		[t],
	)

	const tryCollectBankIdAuth = useCallback(
		async (orderRef) => {
			try {
				const completeData =
					await authenticationService.bankIdv2AuthCollect(
						orderRef,
						false,
						t,
					)
				logEvent(
					`User (${maskSsn(
						completeData.ssn,
					)}) completed BankId signin with status:${
						completeData.isCompleted
					}`,
					{
						ssn: maskSsn(completeData.ssn),
						status: completeData.isCompleted ? 'success' : 'failed',
					},
				)
				if (completeData.isCompleted) {
					authenticationService.redirectTo(
						getReturnUrl(),
						completeData.secret,
					)
				}
			} catch (error) {
				setShouldGenerate(false)
				setError(new Error(t('BankIDCollectFailure')))
				await tryCancelBankIdAuth(orderRef)
			}
		},
		[t, tryCancelBankIdAuth],
	)

	const tryStartBankIdAuth = useCallback(async () => {
		let cancelOrderRef = ''
		try {
			const initiateData =
				await authenticationService.bankIdv2AuthInitiate(getReturnUrl())
			setOrderRef(initiateData.orderRef)
			cancelOrderRef = initiateData.orderRef
			setShouldGenerate(true)

			await tryCollectBankIdAuth(initiateData.orderRef)
		} catch (err) {
			setError(new Error(t('BankIDStartFailure')))
			await tryCancelBankIdAuth(cancelOrderRef)
		}
	}, [t, tryCancelBankIdAuth, tryCollectBankIdAuth])

	useEffect(() => {
		tryStartBankIdAuth()
	}, [tryStartBankIdAuth])

	return {
		orderRef,
		shouldGenerate,
		error,
		tryStartBankIdAuth,
		tryCancelBankIdAuth,
		tryCollectBankIdAuth,
	}
}

const BankIdSeQRContentBlock = () => {
	const { orderRef, shouldGenerate, error } = useBankIdAuth()

	return (
		<div className="bankid-se-container">
			<div className="d-flex flex-column align-items-center">
				{error && <ErrorBox message={error.message} css="mt-2" />}
				<QRCodeWrapper
					orderRef={orderRef}
					shouldGenerate={shouldGenerate}
				/>
			</div>
		</div>
	)
}

export default BankIdSeQRContentBlock
