import { TFunction } from 'i18next'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { SigningUrlType } from 'constants/enums/signingUrlTypes'
import { IApplicationUrlsDto } from 'interfaces/i-signing-urls'
import axiosClients from './axiosClient'

const cdaClient = axiosClients.getCdaClient()

/* Service used when fetching initial application data from phoenix - used in useApplicationData hook */
const getApplicationServices = {
	async GetApplicationInformation(applicationId: number, t: TFunction) {
		const data =
			await cdaClient.get<IApplicationInformation>(
				cdaApiRoutes.getApplicationInformation(applicationId),
			)

		if (data.status === 200) {
			if (data.data) {
				//----for testing purpose---
				// response.data.lifeCycleStatus = LifeCycleStatus.Disbursed
				// response.data.signingUrls.pnSigning.status = Status.Signed
				// response.data.signingUrls.psD2.status = Status.Created
				// response.data.signingUrls.psD2.isMandatory = true
				//-----test end-----
				return data.data
			}
		} else if (data.status === 400) {
			console.error(data.data)
			throw new Error(t('NoApplicationInformationError'))
		} else {
			console.error(data.data)
			throw new Error(t('ApplicationInformationError'))
		}
	},

	async GetSigningUrls(
		applicationId: number,
		signingUrl: SigningUrlType,
		t: TFunction
	) {
		const data = await cdaClient.get<IApplicationUrlsDto>(
			cdaApiRoutes.getSigningUrl(applicationId, signingUrl),
		)

		if (data.status === 200) {
			if (data.data) return data.data
			else throw new Error(t('NoSigningUrlsError'))
		}

		throw new Error(t('SigningUrlsError'))
	},
}

export default getApplicationServices
