import { IUpdateBankInfo } from 'interfaces/i-application-information'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import axiosClients from './axiosClient'

const cdaClient = axiosClients.getCdaClient()

const updateBankInfoServices = {
	async updateBankInfo(bankInfo: IUpdateBankInfo) {
		let data = await cdaClient.post(
			cdaApiRoutes.updateBankInfo,
			bankInfo,
		)

		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong updating bankinfo.')
	},

	async removeDirectDebit(applicationId: number) {
		let data = await cdaClient.post(
			cdaApiRoutes.removedirectdebit,
			{ applicationId: applicationId },
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong removing the direct debit account information.'
		)
	},
}

export default updateBankInfoServices
