import React from 'react'
import appConfig from 'app.config'

import NorwegianBankIdContentBlock from 'components/login-blocks/norwegian-bankid/NorwegianBankIdContentBlock'
import OtpContentBlock from 'components/login-blocks/spainOtp/spainOtpContentBlock'
import authenticationService from 'services/authServices'
// import SignicatBankIdContentBlock from 'components/login-blocks/signicatBankId/signicatBankIdContentBlock'
import { logError } from 'appinsights/appinsights'
import BankIdSeQRContentBlock from 'components/login-blocks/bankIdSe/bankIdSeQRContentBlock'
import BankIdSeAutoStartBlock from 'components/login-blocks/bankIdSe/bankIdSeAutoStartBlock'

export enum AuthMethodType {
	smartIdBasic = 0,
	veriff = 1,
	mobileId = 2,
	cardId = 3,
	smartIdAdv = 4,
	bankId = 5,
	signicat = 6,
	norwegianBankId = 7,
	germanyOtp = 9,
	mitId = 10,
	spainOtp = 11,
	balticOtp = 12,
	norwegianBankIdBiometric = 13,
	bankIdAutoStart = 14,
	bankIdQRStart = 15,
}

export default AuthMethodType

export const authMethods: ISigningButton[] = [
	{
		type: AuthMethodType.signicat,
		country: ['fi'],
		logoClassName: 'signicat',
		showInMobile: true,
		onClick: () => InitiateSignicatSigning(AuthMethodType.signicat),
		// content: <SignicatBankIdContentBlock onError={() => {}}/>
	},
	{
		type: AuthMethodType.mitId,
		country: ['dk'],
		logoClassName: 'mit-id',
		showInMobile: true,
		onClick: () => InitiateSignicatSigning(AuthMethodType.mitId),
	},
	{
		type: AuthMethodType.norwegianBankId,
		country: ['no'],
		logoTitle: 'Kodebrikke eller BankID-app',
		logoClassName: 'norwegian-bank-id',
		showInMobile: true,
		onClick: () => InitiateSignicatSigning(AuthMethodType.norwegianBankId),
		content: <NorwegianBankIdContentBlock authMethodType={AuthMethodType.norwegianBankId} authBtnClassName='NorwegianBankId' />,
	},
	{
		type: AuthMethodType.norwegianBankIdBiometric,
		country: ['no'],
		logoTitle: 'BankID biometri',
		logoClassName: 'norwegian-bank-id',
		showInMobile: true,
		onClick: () => InitiateSignicatSigning(AuthMethodType.norwegianBankIdBiometric),
		content: <NorwegianBankIdContentBlock authMethodType={AuthMethodType.norwegianBankIdBiometric} authBtnClassName='NorwegianBankIdBiometric' />,
	},
	{
		type: AuthMethodType.spainOtp,
		country: ['es'],
		logoTitle: 'Spain (OTP)',
		logoClassName: 'spain-otp',
		showInMobile: true,
		content: <OtpContentBlock />,
		loginContainerHeaderKey: 'Login',
		hideTitleInContent: true,
	},
	{
		type: AuthMethodType.bankIdQRStart,
		country: ['se'],
		logoTitle: 'AuthQrCode',
		logoClassName: 'bank-id',
		showInMobile: true,
		content: <BankIdSeQRContentBlock />,
	},
	{
		type: AuthMethodType.bankIdAutoStart,
		country: ['se'],
		logoTitle: 'AuthAutoStart',
		logoClassName: 'bank-id',
		showInMobile: true,
		content: <BankIdSeAutoStartBlock />,
	},
]

export interface ISigningButton {
	/** Type of authentication */
	type: AuthMethodType

	/** (optional) Title that will be displayed under the icon if provided 	*/
	logoTitle?: string

	/** Classname of the logo to be used */
	logoClassName: string

	/** (optional) If set to true, title will not show in the content tab but only in the card overview */
	hideTitleInContent?: boolean

	/** Set to true if this authentication should be showed in mobile view */
	showInMobile: boolean

	/** Specify countries this type of authentication should be availble to */
	country: string[]

	/** (optional) Element that will be shown when button is pressed. If "onClick" is also populated this will be ignored */
	content?: JSX.Element

	/** (optional) Header to be shown over the login content container */
	loginContainerHeaderKey?: string

	/** (optional) Event that will occur when auth card is pressed. Will ignore content property if populated. */
	onClick?(): Promise<void>
}

/**
 * @returns A list of all authentications that should be used for the country specified in appconfig
 */
export const getAuthMethodsForCurrentCountry = () => {
	return authMethods.filter((method: ISigningButton) =>
		method.country.includes(appConfig.country)
	)
}

/**
 * Based of the authentication type provided; fetches and redirects connected url from signicat for continued signing flow.
 * @async
 */
export async function InitiateSignicatSigning(authType: AuthMethodType) {
	try {
		let redirectUrl: string | undefined

		if (authType === AuthMethodType.signicat) {
			redirectUrl = await authenticationService.signicatRedirectLinkGet()
		}

		if (authType === AuthMethodType.mitId) {
			redirectUrl =
				await authenticationService.signicatMitIdRedirectLinkGet()
		}

		if (
			authType === AuthMethodType.norwegianBankId ||
			authType === AuthMethodType.norwegianBankIdBiometric
		) {
			redirectUrl =
				await authenticationService.norwegianBankIdRedirectLinkGet(
					authType
				)
		}

		if (!redirectUrl) {
			throw new Error(`RedirectUrl not setup for authType ${authType}`)
		}

		window.location.href = redirectUrl
	} catch (error) {
		logError(error as Error)
		throw error
	}
}

export const getAuthButtons = (
	onClick: Function,
	active: AuthMethodType | undefined
) => {
	let filteredMethods = authMethods.filter((method: ISigningButton) =>
		method.country.includes(appConfig.country)
	)

	const isActive = (method: AuthMethodType) =>
		method === active ? ' chosen' : ''

	return {
		buttons: filteredMethods.map((b) => (
			<button
				key={b.type}
				className={
					'btn authButton ' + b.logoClassName + isActive(b.type)
				}
				onClick={() => onClick(b)}></button>
		)),
		initMethod: filteredMethods[0],
		filteredMethods,
	}
}
