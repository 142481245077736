import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import NumberFormat from 'react-number-format'
import '../de-card-application/cardApplicationPage.scss'
import useStateWithSessionStorage from 'hooks/useStateWithSessionStorage'
import { formatBirthDateWithDashes } from 'utils/authHelpers'
import authenticationService from 'services/authServices'
import { appRoutes } from 'constants/routes/appRoutes'
import { checkIsMobile } from 'utils/commonhelpers'
import { CookieType } from 'constants/enums/cookieTypes'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { ValidationError } from 'components/message-boxes/validation-error-box/ValidationErrorBox'
import Heading from 'components/heading/Heading'
import { FooterLoggedOut } from 'components/footer-blocks/footer-logged-out/FooterLoggedOut'
import Menu from 'components/menu/Menu'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'

interface IError {
	applicationId: Error | undefined
	birthDate: Error | undefined
	error: Error | undefined
}

const GermanyAuthentication = () => {
	const { t } = useTranslation()
	const isMobile = checkIsMobile()
	const [loading, setLoading] = useState(false)
	const [value] = useStateWithSessionStorage(CookieType.RedirectUrl)
	const [isAutologin, setAutoLogin] = useState<boolean>(false)

	const [loginDetails, setLoginDetails] = useState({
		applicationId: '',
		birthDate: '',
	})

	const [error, setError] = useState<IError>({
		applicationId: undefined,
		birthDate: undefined,
		error: undefined,
	})

	async function login(birthDate: string, applicationId: string) {
		let err: IError = {
			applicationId: undefined,
			birthDate: undefined,
			error: undefined,
		}
		setError(err)
		if (!/^\d{8}$/.test(birthDate)) {
			err.birthDate = new Error(t('InvalidFormat'))
		}
		if (!/^\d{7,10}$/.test(applicationId)) {
			err.applicationId = new Error(t('InvalidFormat'))
		}

		if (err.birthDate || err.applicationId) {
			setError(err)
			return
		}

		const formatedBirthDate = formatBirthDateWithDashes(birthDate)

		setLoading(true)

		try {
			const { secret, returnUrl } =
				await authenticationService.authenticate(
					authAndSignApiRoutes.auth.germanyAuthenticate, 
					'',
					formatedBirthDate,
					applicationId,
					t,
				)
			sessionStorage.setItem('applicationid', applicationId)
			sessionStorage.setItem('redirectUrl', '/cardapplication')
			authenticationService.redirectTo(
				returnUrl ||
					`${window.location.origin}${appRoutes.callbackPage}`,
				secret
			)
		} catch (e) {
			console.log(e)
			// if (e.message.includes('400')) {
			// 	err.error = new Error(t('IncorrectCode'))
			// 	setError(err)
			// 	setLoading(false)
			// 	setAutoLogin(false)
			// 	return
			// }
			err.error = new Error(t('AuthenticationError'))
			setError(err)
			setLoading(false)
			setAutoLogin(false)
		}
	}

	useEffect(() => {}, [error])

	useEffect(() => {
		let qs = queryString.parse(queryString.extract(value))

		if (qs.applicationid !== undefined && qs.dob !== undefined) {
			setAutoLogin(true)
			login(qs.dob as string, qs.applicationid as string)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const formatBirthDate = (str: string) => str.split('.').reverse().join('')

	return (
		<div id="login-page" className="card-applicationpage auth login-bg">
			<Menu />
			<div className="de-page-color">
				<div className="fixed-width text-center">
					{isAutologin ? (
						<div className="d-flex justify-content-center align-items-center vh-100 callback">
							<CircleSpinner />
						</div>
					) : (
						<>
							<div
								style={{
									height: isMobile ? '9vh' : '8vh',
								}}></div>
								<Heading
									title="HerzlichWillkommen"
									description="LoginHeaderText"
								/>
							<section className="container sign p-4">
								<div className="my-4">
									{t('IhrPersönlicherZugang')}
								</div>
								<input
									maxLength={10}
									value={loginDetails.applicationId}
									onChange={({ currentTarget: { value } }) =>
										setLoginDetails({
											...loginDetails,
											applicationId: value,
										})
									}
									name="applicationId"
									placeholder={t('Code1')}
									className="form-control"
									type="tel"
								/>
								<div className="description">
									{t('DescriptionApplicationId')}
								</div>
								{error.applicationId && (
									<ValidationError
										message={error.applicationId.message}
									/>
								)}
								<NumberFormat
									format="##.##.####"
									placeholder={t('Code2')}
									// prettier-ignore
									mask={['T','T','M','M','J','J','J','J']}
									type="tel"
									className="form-control"
									onValueChange={(value) =>
										setLoginDetails({
											...loginDetails,
											birthDate: value.formattedValue,
										})
									}
									isNumericString={true}
									onKeyDown={(e: React.KeyboardEvent) => {
										e.key === 'Enter' &&
											login(
												formatBirthDate(
													loginDetails.birthDate
												),
												loginDetails.applicationId
											)
									}}
								/>
								{error.birthDate && (
									<ValidationError
										message={error.birthDate.message}
									/>
								)}
								{error.error && (
									<ValidationError
										message={error.error.message}
									/>
								)}
								{loading ? (
									<div className="col-spinner">
										<CircleSpinner />
									</div>
								) : (
									<button
										onClick={() => {
											login(
												formatBirthDate(
													loginDetails.birthDate
												),
												loginDetails.applicationId
											)
										}}
										className="btn btn-default my-4">
										{t('SignIn')}
									</button>
								)}
							</section>
						</>
					)}
				</div>
			</div>
			<FooterLoggedOut />
		</div>
	)
}

export default GermanyAuthentication
