import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { IUpdateLoanInfo } from 'interfaces/i-confirm-loan-offer'
import { TFunction } from 'i18next'
import axiosClients from './axiosClient'

const cdaClient = axiosClients.getCdaClient()

const creditDecisionServices = {
	async runPreliminaryCreditDecision(
		applicationId: string,
		cdaApiRoute: string
	) {
		const data = await cdaClient.post(
			cdaApiRoute,
			{
				applicationId: applicationId,
			},
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong running preliminary credit decision.'
		)
	},

	async runcreditdecision(applicationId: string, cdaApiRoute: string) {
		const data = await cdaClient.post(
			cdaApiRoute,
			{
				applicationId: applicationId,
			},
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong updating running credit decision.'
		)
	},

	async updateLoanData(
		applicationId: number,
		loanInfo: IUpdateLoanInfo,
		t: TFunction
	) {
		const data = await cdaClient.post(
			cdaApiRoutes.updateLoanData,
			{
				applicationId: applicationId,
				loanInfo: loanInfo,
			},
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('ErrorMsg'))
	},
}

export default creditDecisionServices
