import { DocumentTypes } from 'constants/enums/documentTypes'
import { IUploadDocuments } from 'interfaces/i-upload-documents'

export const DocumentTypesSpain: IUploadDocuments[] = [
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.Payroll,
		maxFiles: 5,
		header: 'Payroll',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.EmploymentCertificate,
		maxFiles: 5,
		header: 'EmploymentCertificate',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.BankStatement,
		maxFiles: 5,
		header: 'BankStatement',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.AddressProof,
		maxFiles: 5,
		header: 'AddressProof',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.TaxReturn,
		maxFiles: 5,
		header: 'Taxreturn',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.Other,
		maxFiles: 5,
		header: 'OtherDocuments',
		bottomText: 'OtherDocumentsDescription',
	},
]

export const DocumentTypesSpainPsd2Signed: IUploadDocuments[] = [
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.Other,
		maxFiles: 5,
		header: 'OtherDocuments',
		bottomText: 'OtherDocumentsDescription',
	},
]

export const DocumentTypesDenmark: IUploadDocuments[] = [
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.TaxReturn,
		maxFiles: 5,
		header: 'OtherDocuments',
	},
]

export const DocumentTypesNorway: IUploadDocuments[] = [
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.Payroll,
		maxFiles: 3,
		header: 'Payroll',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.TaxReturn,
		maxFiles: 3,
		header: 'Taxreturn',
	},
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.BankStatement,
		maxFiles: 3,
		header: 'OtherDocuments',
		bottomText: 'OtherDocumentsDescription',
	},
]

export const DocumentTypesSweden: IUploadDocuments[] = [
	{
		typeOfDocuments: 'image/jpeg, image/png, application/pdf, .doc, .docx',
		text: '',
		documentTypes: DocumentTypes.TaxReturn,
		maxFiles: 5,
		header: 'OtherDocuments',
	},
]
