import { TFunction } from 'i18next'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { IUpdateApplicationInformation } from 'interfaces/i-update-application'
import { IAddPublicNote } from 'interfaces/i-add-public-note'
import { IPpiData } from 'interfaces/i-ppi'
import { IUpdateCustomerConsents } from 'interfaces/i-update-customer-consents'
import { IUpdateMissingKyc } from 'interfaces/i-update-missing-kyc'
import { ISblData } from 'interfaces/i-sbl'
import axiosClients from './axiosClient'

const cdaClient = axiosClients.getCdaClient()

const updateApplicationServices = {
	async updateLoanApplication(
		loanApplicationData: IUpdateApplicationInformation,
		t: TFunction
	) {
		const data = await cdaClient.post(
			cdaApiRoutes.updateApplicationInformation,
			{
				applicationId: loanApplicationData.applicationId,
				product: loanApplicationData.product,
				applicant: loanApplicationData.applicant,
				bankInfo: loanApplicationData.bankInfo,
			},
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(
			'Something went wrong updating loan application information.'
		)
	},

	async addPublicNote(addPublicNoteData: IAddPublicNote, t: TFunction) {
		if (!addPublicNoteData) return
		const data = await cdaClient.post(
			cdaApiRoutes.addPublicNote,
			addPublicNoteData,
		)
		if (data.status === 200) return
		throw new Error(t('Something went wrong tryling to update public note'))
	},

	async updatePpi(ppiData: IPpiData, t: TFunction) {
		const data = await cdaClient.post(
			cdaApiRoutes.updatePpi,
			ppiData,
		)
		if (data.status === 200) return data.status
		throw new Error(t('PpiError'))
	},

	async updateCustomerConsent(
		customerConsents: IUpdateCustomerConsents,
		t: TFunction
	) {
		let data = await cdaClient.post(
			cdaApiRoutes.updateCustomerConsents,
			customerConsents,
		)

		if (data.status === 200) {
			return data.status
		}
		throw new Error(t('ErrorMsg'))
	},

	async updateMissingKyc(
		updateMissingKycData: IUpdateMissingKyc,
		t: TFunction
	) {
		if (!updateMissingKycData) return

		const data = await cdaClient.post(
			cdaApiRoutes.updateMissingKyc,
			updateMissingKycData,
		)
		if (data.status === 200) return
		throw new Error(t('UpdatingMissingKycError'))
	},

	async updateSbl(sblData: ISblData, t: TFunction) {
		try {
			const data = await cdaClient.post(
				cdaApiRoutes.updateSbl,
				sblData,
			)
			if (data.status === 200) return
			throw new Error(t('SblError'))
		} catch (error) {
			console.log(error)
			throw new Error(t('SblError'))
		}
	},
}

export default updateApplicationServices
