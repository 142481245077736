import appConfig from 'app.config'

export const authAndSignApiRoutes = {
	auth: {
		idCardAuth: (id: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/idcard/${id || ''}`,
		mobileIdAuthInitiate: `${appConfig.authorityBaseUrl}/apiauth/mobileid/initiate`,
		mobileIdAuthComplete: `${appConfig.authorityBaseUrl}/apiauth/mobileid/complete`,
		smartIdAuthInitiate: `${appConfig.authorityBaseUrl}/apiauth/smartid/initiate`,
		smartIdAuthComplete: `${appConfig.authorityBaseUrl}/apiauth/smartid/complete`,
		signicatAuthLink: `${appConfig.authorityBaseUrl}/apiauth/signicat/link`,
		signicatAuthResult: `${appConfig.authorityBaseUrl}/apiauth/signicat/result`,
		signicatAuthMitId: `${appConfig.authorityBaseUrl}/apiauth/signicat/mitid`,
		norwegianBankIdAuthLink: `${appConfig.authorityBaseUrl}/apiauth/norwegianbankid/link`,
		norwegianBankIdAuthResult: `${appConfig.authorityBaseUrl}/apiauth/norwegianbankid/result`,
		norwegianBankIdApplicationAuthResult: `${appConfig.authorityBaseUrl}/apiauth/norwegianbankid/application/result`,
		bankIdAuthInitiate: `${appConfig.authorityBaseUrl}/apiauth/bankid/initiate`,
		bankIdAuthComplete: `${appConfig.authorityBaseUrl}/apiauth/bankid/complete`,
		bankIdAuthCancel: (orderRef: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/bankid/cancel?orderRef=${orderRef}`,
		austriaOtpStart: `${appConfig.authorityBaseUrl}/apiauth/austria/authenticate/otp/start`,
		austriaOtpComplete: `${appConfig.authorityBaseUrl}/apiauth/austria/authenticate/otp/complete`,
		spainOtpStart: `${appConfig.authorityBaseUrl}/otpauth/application/start`,
		spainOtpComplete: `${appConfig.authorityBaseUrl}/otpauth/complete`,
		spainAuthenticate: `${appConfig.authorityBaseUrl}/apiauth/Spain/authenticate`,
		loanOtpStart: `${appConfig.authorityBaseUrl}/otpauth/loan/start`,
		electronicIDToken: (applicationId: string, product: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/signicat/electronicid/videoid?applicationId=${applicationId}&product=${product}`,
		redirectTo: `${appConfig.authorityBaseUrl}/account/redirectto`,
		getArtifactBbBank: (requestId: string, taskId: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/norwegianbankid/artifact?requestId=${requestId}&taskId=${taskId}`,
		getArtifactDk: (requestId: string, taskId: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/signicat/dkartifact?requestId=${requestId}&taskId=${taskId}`,
		getArtifactSe: (requestId: string, taskId: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/signicat/seartifact?requestId=${requestId}&taskId=${taskId}`,
		getArtifactFi: (requestId: string, taskId: string) =>
			`${appConfig.authorityBaseUrl}/apiauth/signicat/artifact?requestId=${requestId}&taskId=${taskId}`,
		germanyAuthenticate: `${appConfig.authorityBaseUrl}/apiauth/germany/authenticate`,
		bankIdv2AuthInitiate: `${appConfig.authorityBaseUrl}/v2/apiauth/bankid/initiate`,
		bankIdv2AuthCollect: `${appConfig.authorityBaseUrl}/v2/apiauth/bankid/collect`,
		bankIdv2AuthCancel: `${appConfig.authorityBaseUrl}/v2/apiauth/bankid/cancel`,
		bankIdv2GenerateQrCode: `${appConfig.authorityBaseUrl}/v2/apiauth/bankid/qrcode/generate`,
	},
	sign: {
		signPnInit: `${appConfig.esignBaseUrl}/api/esigning/initiateESigningSpain`,
		signPnComplete: `${appConfig.esignBaseUrl}/api/esigning/completeESigningSpain`,
		signPnResendOtp: `${appConfig.esignBaseUrl}/api/esigning/resendotpESigningSpain`,
		attachVideoIdentification: `${appConfig.esignBaseUrl}/api/esigning/attachVideoIdentification`,
		createVideoIdentity: `${appConfig.esignBaseUrl}/api/esigning/createVideoIdentity`,
	}
}
