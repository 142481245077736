import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import authenticationService from 'services/authServices'
import { getReturnUrl } from 'utils/authHelpers'
import { checkIsMobile, isIOS } from 'utils/commonhelpers'

const useBankIdAuth = () => {
	const { t } = useTranslation()
	const [error, setError] = useState<Error | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const isMobile = checkIsMobile()

	const initiateBankIdAuth = useCallback(async () => {
		setIsLoading(true)
		setError(null)
		try {
			const returnUrl =
				isMobile && isIOS()
					? encodeURIComponent(getReturnUrl('/bankid-callback'))
					: 'null'

			const initiateData =
				await authenticationService.bankIdv2AuthInitiate(
					encodeURIComponent(returnUrl),
				)

			// sessionStorage has limitations and will not work on iOS devices because we have redirect to a new tab
			localStorage.setItem('bankid_orderRef', initiateData.orderRef)

			const redirectUrl =
				isMobile && isIOS()
					? initiateData.iosMobileBankIdAutoStartUrl
					: initiateData.bankIdAutoStartUrl
			return { redirectUrl }
		} catch (error) {
			setError(new Error(t('BankIDInitiateFailure')))
			setIsLoading(false)
			throw error
		}
	}, [t, isMobile])

	const cancelBankIdAuth = useCallback(async () => {
		const orderRef = localStorage.getItem('bankid_orderRef')
		if (!orderRef) return
		try {
			await authenticationService.bankIdv2AuthCancel(orderRef, t)
		} catch (err) {
			setError(new Error(t('BankIdCancelFailure')))
		}
	}, [t])

	return {
		initiateBankIdAuth,
		cancelBankIdAuth,
		error,
		isLoading,
		setIsLoading,
	}
}

const BankIdSeAutoStartBlock = () => {
	const { t } = useTranslation()
	const { initiateBankIdAuth, cancelBankIdAuth, error, isLoading } =
		useBankIdAuth()
	const isMobile = checkIsMobile()

	useEffect(() => {
		const authenticate = async () => {
			try {
				const { redirectUrl } = await initiateBankIdAuth()

				// start BankID app
				window.location.href = redirectUrl

				// continue with collecting the authorizaiton status,
				// ios devices are being autmatically redirected to the bankid-callback page
				if (!isMobile || !isIOS())
					window.location.href = getReturnUrl('/bankid-callback')
			} catch (error) {
				await cancelBankIdAuth()
			}
		}

		authenticate()
	}, [initiateBankIdAuth, cancelBankIdAuth, isMobile])

	return (
		<div className="bankid-se-container">
			<div className="d-flex flex-column align-items-center">
				{isMobile ? (
					<b>{t('BankIdTryingToLaunchApp')}</b>
				) : (
					t('AuthAutoStartInfo')
				)}
				{error && <ErrorBox message={error.message} css="mt-2" />}
				{isLoading && <CircleSpinner className="my-4" />}
			</div>
		</div>
	)
}

export default BankIdSeAutoStartBlock
