import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'

import appConfig from 'app.config'
import MissingKycModal from './MissingKycModal/MissingKycModal'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationInformation } from 'interfaces/i-application-information'
import updateApplicationServices from 'services/updateApplicationServices'
import { IUpdateMissingKyc } from 'interfaces/i-update-missing-kyc'
import { getErrorWithFullMessage } from 'utils/authHelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './MissingKyc.scss'

const MissingKyc = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [error, setError] = useState<Error | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)

	let formSchema = FormFactory(
		CustomerForm.ApplicationMissingKyc,
		{
			formName: 'applicationMissingKyc',
			blockName: 'applicationMissingKyc',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const valuesToSubmit = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updatePublicNote = async (formData: any) => {
		if (formData?.purposeOfLoanOther) {
			try {
				await updateApplicationServices.addPublicNote(
					{
						applicationId:
							loanApplication.applicationData?.loanNumber!,
						note: formData?.purposeOfLoanOther,
					},
					t
				)
			} catch (error) {
				console.warn(error)
				setError(new Error(t('UpdatingMissingKycError')))
			}
		}
	}

	const onSubmit = async (formData: any) => {
		setLoading(true)
		setError(undefined)
		try {
			await updatePublicNote(formData)
			await updateApplicationServices
				.updateMissingKyc(
					{
						applicationId:
							loanApplication.applicationData?.loanNumber,
						purposeOfLoan: formData?.purposeOfLoan,
						politicallyExposedPerson: formData?.pep,
						connectedToPep: formData?.connectedToPep,
						isCoapplicant: loanApplication.applicationData?.isCoApplicant
					} as IUpdateMissingKyc,
					t
				)
				.then(() => {
					dispatchMsp({
						type: MSP_ACTIONS.SET_INDEX,
						payload: {
							index: msp.index + 1,
						},
					})
				})
		} catch (error) {
			console.warn(error)
			setError(getErrorWithFullMessage(error as Error))
		} finally {
			setLoading(false)
		}
	}

	const checkPepValues = (formData: any) => {
		if (formData.pep === true) {
			if (loanApplication.isPep) {
				onSubmit(formData)
			} else {
				dispatchLoanApplication({
					type: LOAN_APPLICATION_ACTIONS.SET_IS_PEP,
					payload: {
						...loanApplication,
						showPepModal: true,
					},
				})
			}
		} else {
			onSubmit(formData)
		}
	}

	useEffect(
		() => {
			if (loanApplication.isPep && !loanApplication.showPepModal) {
				onSubmit({
					pep: valuesToSubmit?.pep.value,
					connectedToPep: valuesToSubmit?.connectedToPep.value,
				})
			}
			return () => {}
		},
		[loanApplication, valuesToSubmit] //eslint-disable-line
	)

	return (
		<div className="missing-kyc">
			<div className="signing-section my-3 pn">
				{t('forms:ApplicationMissingKycDescription')}
			</div>
			{appConfig.country === 'no' ? (
				<div className="appMissingDescSupport">
					{t('forms:ApplicationMissingKycDescriptionSupport')}
				</div>
			) : null}

			{loading ? (
				<div className="col-spinner mt-5">
					<CircleSpinner />
				</div>
			) : (
				<React.Fragment>
					<div className="missing-kyc-form">
						<FormBuilder
							formSchema={formSchema!}
							apiError={undefined}
							showErrorsInForm={true}
							showSubmitButton={true}
							onSubmit={(values) => {
								if (appConfig.country === 'no') {
									checkPepValues(values)
								} else {
									onSubmit(values)
								}
							}}
						/>
					</div>
					{error && <ErrorBox message={t('ErrorMsg')} />}
					{appConfig.country === 'no' ? <MissingKycModal /> : null}
				</React.Fragment>
			)}
		</div>
	)
}

export default MissingKyc
