import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { isPnStatusSigned, onPnSignClick } from 'utils/loanHelpers'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ButtonSpinner from 'components/button-spinner/ButtonSpinner'

import './SignPn.scss'
import { useApplicationData } from 'hooks/useApplicationData'

export const SignPnSe = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const applicationId =
		loanApplication.applicationData?.loanNumber.toString() ||
		sessionStorage.getItem('applicationid') ||
		null

	const { applicationData, loading: applicationLoading } = useApplicationData(
		{ applicationId, t }
	)

	useEffect(() => {
		if (applicationData) {
			dispatchLoanApplication({
				type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
				payload: applicationData,
			})
		}
	}, [applicationData, dispatchLoanApplication])

	useEffect(() => {
		const checkPnStatus = () => {
			if (isPnStatusSigned(applicationData!)) {
				next()
			}
		}
		if (applicationData) {
			checkPnStatus()
		}
	}, [applicationData])

	const signClick = () => {
		const status = applicationData?.lifeCycleStatus.toLowerCase()
		if (status === 'denied' || status === 'canceled') {
			setErrorMessage(t('ApplicationRejectedPsd2'))
			return
		}
		setLoading(true)
		onPnSignClick(applicationData!, loanApplication.signingUrls!, t)
	}

	const next = () => {
		dispatchMsp({
			type: MSP_ACTIONS.SET_INDEX,
			payload: {
				index: msp.index + 1,
			},
		})
	}

	return (
		<div className="sign-pn denmark">
			<div className="signing-section">
				<h6>{t('PromissoryNote')}</h6>
				{applicationLoading ? (
					<CircleSpinner />
				) : (
					<>
						<div className="sign-pn-information-section">
							<div>{t('PromissoryNoteSigningLine1')}</div>
							<div>{t('PromissoryNoteSigningLine2')}</div>
						</div>
						{errorMessage && (
							<div className="error-message">{errorMessage}</div>
						)}
						<div className="sign-pn-button-section">
							<button
								className="btn btn-primary"
								onClick={signClick}
								disabled={loading || !!errorMessage}>
								{loading ? (
									<div className="sign-pn-spinner-container">
										<ButtonSpinner />
									</div>
								) : (
									t('SIGNPN')
								)}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
