import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import AppContext from 'contexts/AppContext'
import { ApplicationType } from 'constants/enums/applicationTypes'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'

import './Ppi.scss'

export const PpiNo = () => {
	const { t } = useTranslation()
	const { applicationType } = useContext(AppContext)
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const [addPpi, setPpi] = useState<boolean>(true)

	const updatePpi = () => {
		return dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_PPI,
			payload: {
				...loanApplication,
				hasAddedPpi: addPpi,
			},
		})
	}

	useEffect(
		() => {
			updatePpi()
		},
		[addPpi] //eslint-disable-line
	)

	const ppiMessageType =
		applicationType === ApplicationType.Loan ? 'GETPPI' : 'GETPPICred'

	return (
		<div className="payment-protection-insurance">
			<ReadMoreExpander
				expanded={false}
				showExpander={true}
				contentTop={true}
				collapsedHeight={'6rem'}
				children={
					<div>
						<label className="checkbox-container">
							<input
								type="checkbox"
								checked={addPpi}
								onChange={() => setPpi(!addPpi)}
							/>
							<span className="checkmark"></span>
							<b>{t(ppiMessageType)}</b>
						</label>
						<div className="ppi-terms">
							<div className="ppi-information-section">
								{t('PpiDesc')}
							</div>
							<div>
								<Trans t={t} i18nKey="PpiTerms">
									<div>
										<b>The insurance includes:</b>
									</div>
									<ul>
										<li>Involuntary loss of work.</li>
										<li>
											Temporary and complete disability
											due to sudden and unexpected
											accident damage or disease.
										</li>
										<li>Hospital stay</li>
										<li>Loss of life</li>
									</ul>
									<div>
										<b>The insurance includes:</b>
									</div>
									<ul>
										<li>
											Private person or self-employed
											between 18 and 60 years.
										</li>
										<li>
											Permanent employee at one and the
											same company employer for a period
											of at least six consecutive months
											in directly before the time of
											drawing of the insurance.
										</li>
										<li>
											Has a job title such as corresponds
											to a minimum of 22 hours each week.
										</li>
										<li>
											Fully working and not receive sick
											pay, work clearance allowance or
											similar.
										</li>
										<li>
											You must not be familiar with
											understanding hospitalization,
											unemployment or layoff.
										</li>
										<li>
											Member of Norwegian National
											Insurance at the time of drawing of
											insurance in (applies not
											independently trader)
										</li>
									</ul>
									<>
										Forsikringsbevis og fullstendige vilkår
										kan du finne ved å følge lenken under.
									</>
								</Trans>
							</div>
						</div>
						<div className="ppi-link-section">
							<a
								href={
									'https://tfbank.no/media/pliemud3/tf-bank-no_villkor_laneskydd_5_0-combined.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								{t('PpiTermsReadMore')}
							</a>
							<a
								href={
									'https://tfbank.no/media/1xwpxpap/tf-bank-norge-laneforsikring_informasjon-for-kjop-per-25-sept-2024-2_0-002-copy.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								{t('PpiPrePurchaseInformation')}
							</a>
							<a
								href={
									'https://tfbank.no/media/mevdd2i0/36352_ipid_laneforsikring-18-64_2_0-002.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								{t('PpiInsuranceDocument')}
							</a>
						</div>
					</div>
				}
			/>
		</div>
	)
}
