import { TFunction } from 'i18next'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import { IAttachVideoIdentification, ICreateVideoIdentity } from 'interfaces/i-video-id'
import axiosClients from './axiosClient'

const signApiClient = axiosClients.getSignApiClient()

const videoIdServices = {
	async attachVideoIdentification(
		videoIdentificationData: IAttachVideoIdentification,
		t: TFunction
	) {
		const data = await signApiClient.post(
			authAndSignApiRoutes.sign.attachVideoIdentification,
			videoIdentificationData,
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('VideoIdError'))
	},
		
	async createVideoIdentity(
		videoIdentityData: ICreateVideoIdentity,
		t: TFunction
	) {
		const data = await signApiClient.post(
			authAndSignApiRoutes.sign.createVideoIdentity,
			videoIdentityData,
		)
		if (data.status === 200) {
			return data
		}
		throw new Error(t('VideoIdError'))
	},
}

export default videoIdServices
