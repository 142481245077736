import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import authServices from 'services/authServices'
import { appRoutes } from 'constants/routes/appRoutes'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { getReturnUrl } from 'utils/authHelpers'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import { CookieType } from 'constants/enums/cookieTypes'
import { ProductType } from 'constants/enums/productTypes'

const SpainAuthentication = () => {
	const { t } = useTranslation()
	const [isAutologin, setAutoLogin] = useState<boolean>(true)

	async function login(
		ssn: string,
		applicationId: string,
	) {
		try {
			const { secret, returnUrl } =
				await authServices.authenticate(
					authAndSignApiRoutes.auth.spainAuthenticate, 
					ssn,
					'',
					applicationId,
					t,
				)
			sessionStorage.setItem(
				CookieType.RedirectUrl,
				`/application?applicationid=${applicationId}`,
			)
			authServices.redirectTo(
				returnUrl || getReturnUrl(),
				secret,
			)
		} catch (e) {
			console.log(e)
			setAutoLogin(false)
		}
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const ssn = searchParams.get('ssn')
		const applicationId = searchParams.get('applicationid')
		const product = searchParams.get('product')

		if (ssn && applicationId && product === ProductType.EspCreditCard.toString()) {
			login(ssn, applicationId)
		}
		else {
			setAutoLogin(false)
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Container className="spinner-container d-flex justify-content-center align-items-center">
			{isAutologin ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				(window.location.href = appRoutes.login)
			)}
		</Container>
	)
}

export default SpainAuthentication
