import axios, { AxiosError, AxiosInstance } from 'axios'
import axiosRetry, { exponentialDelay, isNetworkError, isRetryableError } from 'axios-retry'
import { setUpApplicationInterceptorsFor } from './interceptors'

function createAxiosInstance(useAuth: boolean, useRetry: boolean){
	let axiosClient = setUpApplicationInterceptorsFor(axios.create({}), useAuth)

	axiosClient.defaults.headers.post['Content-Type'] = 'application/json'
	axiosClient.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
	axiosClient.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept'

	if(useRetry)
		axiosRetry(axiosClient, {retryCondition: isNetworkOrRetryableError, retryDelay: exponentialDelay}) //by default will retry 3 times

	return axiosClient
}

var signApiClient : AxiosInstance = createAxiosInstance(true, true)
var signApiUnauthenticatedClient : AxiosInstance = createAxiosInstance(false, true)
var identityClient : AxiosInstance = createAxiosInstance(false, true)
var cdaClient : AxiosInstance = createAxiosInstance(true, true)


//this includes POST method
function isNetworkOrRetryableError(error: AxiosError) {
	return isNetworkError(error) || isRetryableError(error)
}


var axiosClients = {
	getCdaClient() 
	{
		return cdaClient
	},
	getIdentityClient() 
	{
		return identityClient
	},
	getSignApiClient() : AxiosInstance
	{
		return signApiClient
	},
	getSignApiUnauthenticatedClient() 
	{
		return signApiUnauthenticatedClient
	},
}


export default axiosClients;