import { TFunction } from 'i18next'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import axiosClients from './axiosClient'

const cdaClient = axiosClients.getCdaClient()

const documentServices = {
	async UploadDocument(
		uploadDocumentRequest: FormData,
		applicationId: number,
		t: TFunction
	) {
		let headers = {
			headers: {
				'Content-Type': 'application/json',
			},
		}
		const response = await cdaClient.post(
			cdaApiRoutes.uploadDocument(applicationId),
			uploadDocumentRequest,
			headers
		)
		if (response.status !== 200) {
			throw new Error(t('UploadDocumentError'))
		}
	},

	async getDownloadDocuments(applicationId: string, t: TFunction) {
		let data = await cdaClient.get<any>(
			cdaApiRoutes.getDownloadDocuments(applicationId),
		)
		if (data.status === 200) {
			return data.data.documents
		}
		throw new Error(t('DownloadingFileError'))
	},

	async getDownloadDocumentById(documentId: string) {
		const data = await cdaClient.get<any>(
			cdaApiRoutes.getDownloadDocumentById(documentId),
			{
				responseType: 'blob',
			}
		)
		if (data.status === 200) {
			console.log(data)
			return {
				blob: data.data as Blob,
			}
		}
		throw new Error('Could not download document by id')
	},
}

export default documentServices
