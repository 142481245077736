import appConfig from 'app.config'
import { appInsights, logError, logEvent } from 'appinsights/appinsights'
import {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios'
import { User } from 'oidc-client'
import { getAccessTokenParam, userManager } from 'utils/authHelpers'
import { waitForLanguage } from 'utils/commonhelpers'
import { getCountryA3 } from 'utils/countryHelpers'

let tokenInfo: Partial<User> = {}

const onRequest = async (
	config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	config.headers['Accept-Language'] = await waitForLanguage()
	consoleLog(`[request] [${JSON.stringify(config)}]`)
	logEvent('ApplicationPageRequest', { config: `${JSON.stringify(config)}` })
	return config
}

const onRequestWithAuth = async (
	config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	await setHeadersWithJwtToken(config)
	config.headers['Accept-Language'] = await waitForLanguage()
	consoleLog(`[request] [${JSON.stringify(config)}]`)
	logEvent('ApplicationPageRequest', { config: `${JSON.stringify(config)}` })
	return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
	consoleErr(`[request error] [${error.toJSON}]`)
	logError(
		{
			...error,
			message: `${error.message} with message: "${error.request?.data.message}"`,
		},
		{ ErrorMessage: error.request?.data.message }
	)
	return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
	consoleLog(`[response] [${JSON.stringify(response)}]`)
	logEvent('ApplicationPageResponse', {
		url: response.config.url,
		status: response.status,
		data: response.data,
	})
	return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
	consoleErr(`[response error] [${JSON.stringify(error)}]`)
	logError(
		{
			...error,
			message: `${error.message} with message: "${error.response?.data.message}"`,
		},
		{ ErrorMessage: error.response?.data.message }
	)

	return Promise.reject(error)
}

const logInConsole = appConfig.environment !== 'production'
const consoleLog = (message: string) => {
	if (logInConsole) console.log(message)
}
const consoleErr = (message: string) => {
	if (logInConsole) console.error(message)
}

async function setHeadersWithJwtToken(config: AxiosRequestConfig) {
	const { access_token, expires_at, profile } =
		(await userManager.getUser()) || { access_token: getAccessTokenParam() }
	if (access_token && tokenInfo.access_token !== access_token) {
		tokenInfo = { expires_at, profile, access_token }
		appInsights.trackEvent({
			name: 'user got a token',
			properties: { expires_at, profile },
		})
	}

	config.headers['Authorization'] = access_token && `Bearer ${access_token}`;
	config.headers['Customer-Id'] = profile?.sub;
	config.headers['System-Version'] = 'ApplicationPage';
	config.headers['market'] = getCountryA3();
}

export function setUpApplicationInterceptorsFor(
	instance: AxiosInstance,
	useAuth: boolean
): AxiosInstance {
	if(useAuth){
		instance.interceptors.request.use(onRequestWithAuth, onRequestError)
	}
	else{
		instance.interceptors.request.use(onRequest, onRequestError)
	}
	instance.interceptors.response.use(onResponse, onResponseError)
	return instance
}
