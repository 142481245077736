import appConfig from 'app.config'
import queryString from 'query-string'
import { UserManagerSettings, UserManager } from 'oidc-client'
import axios, { AxiosError } from 'axios'

export function getClientSettings(
	response_mode: string | undefined = undefined
): UserManagerSettings {
	return {
		authority: appConfig.authorityBaseUrl,
		client_id: 'mypages_ui',
		redirect_uri: `${window.location.origin + '/callback'}`,
		response_type: 'code',
		loadUserInfo: true,
		post_logout_redirect_uri: `${
			window.location.origin + '/logoutcallback'
		}`,
		scope: appConfig.country === 'es' 
			? 'openid tf_bank_customer_data_api tf_bank_signing_api' 
			: 'openid tf_bank_customer_data_api', // List of scopes separated by space. For spain application pages we need to ask for sign api scope
		automaticSilentRenew: true,
		response_mode,
		clockSkew: 15 * 60, //default 5min
	}
}

export function getUserManager() {
	return new UserManager(getClientSettings())
}

export const userManager = getUserManager()

export function getAccessTokenParam() {
	const props = queryString.parse(window.location.search) as {
		access_token: string
	}

	console.info('url_access_token', props && props.access_token)
	return props ? props.access_token : ''
}

export async function getSubFromClaims() {
	const { profile } = (await userManager.getUser()) || {
		access_token: getAccessTokenParam(),
	}
	return profile?.sub
}

/**
 * Get user birth date from claims
 * @returns bithDate
 */
export async function getBirthdateFromClaims() {
	const { profile } = (await userManager.getUser()) || {
		access_token: getAccessTokenParam(),
	}
	return profile?.[
		'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth'
	]
}

/**
 * Returns the Location object's URL's origin + callback path.
 * @param {string} callbackPath to callback
 */
export function getReturnUrl(callbackPath: string = '/callback') {
	return window.location.origin + callbackPath
}

/**
 * Returns the Location object's URL's origin + callback path.
 * @param {string} callbackPath to callback
 */
export function getSignicatReturnUrl(
	callbackPath: string = '/signicatcallback'
) {
	return window.location.origin + callbackPath
}

/**
 * Returns the Location object's URL's origin + callback path.
 * @param {string} callbackPath to callback
 */
export function getNorwegianBankIdReturnUrl(
	callbackPath: string = '/norwegianbankidcallback'
) {
	return window.location.origin + callbackPath
}

/**
 * Returns masked version of ssn
 * @param ssn
 * @returns
 */
export function maskSsn(ssn: string) {
	if (appConfig.country === 'se') return ssn.substr(0, ssn.length - 4)
	return ssn
}

export function formatBirthDateWithDashes(birthDateOnlyNumbers: string) {
	const formattedBirthDate =
		birthDateOnlyNumbers.slice(0, 4) +
		'-' +
		birthDateOnlyNumbers.slice(4, 6) +
		'-' +
		birthDateOnlyNumbers.slice(6, 8)
	return formattedBirthDate
}

export function getErrorWithFullMessage(error: Error | AxiosError) {
	console.warn(error)

	if (typeof error !== 'object') {
		return error
	}

	if ('isAxiosError' in error && error.response && error.response.data) {
		return new Error(
			error.response.status < 500 ? error.response.data : 'Network Error'
		)
	}

	return new Error(error.message)
}

function retrieveAxiosCancelToken() {
	let cancelSource = axios.CancelToken.source()

	/** Enhance reset functionality to immediately replace the cancelSource
	with a new one, ensuring the old one cannot be reused. */
	const resetCancelToken = () => {
		cancelSource = axios.CancelToken.source()
	}

	/** Getter for the token to ensure encapsulation and direct access
	to the current token without directly exposing cancelSource. */
	const getToken = () => {
		return cancelSource.token
	}

	/** Cancel function that directly uses the current cancelSource,
	allowing for cancellation without external access to cancelSource. */
	const cancel = (message: string) => {
		cancelSource.cancel(message)
	}

	return { getToken, resetCancelToken, cancel }
}

export const authenticationServiceCancelToken = retrieveAxiosCancelToken()
