import { TFunction } from 'i18next'
import { cdaApiRoutes } from 'constants/routes/cdaApiRoutes'
import { authAndSignApiRoutes } from 'constants/routes/authAndSignApiRoutes'
import {
	ICompleteESigningES,
	IInitiateESigningES,
	IResendOtpESigningES,
} from 'interfaces/i-sign-pn'
import axiosClients from './axiosClient'

const signApiClient = axiosClients.getSignApiClient()
const signApiUnauthenticatedClient = axiosClients.getSignApiUnauthenticatedClient()
const cdaClient = axiosClients.getCdaClient()

const pnServices = {
	async signPnInit(initPnSignES: IInitiateESigningES) {
		let data = await signApiClient.post(
			authAndSignApiRoutes.sign.signPnInit,
			initPnSignES
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong when trying to start signing the Promissory note'
		)
	},

	async signPnComplete(completePnSignES: ICompleteESigningES) {
		let data = await signApiClient.post(
			authAndSignApiRoutes.sign.signPnComplete,
			completePnSignES
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error(
			'Something went wrong when trying to complete signing the Promissory note'
		)
	},

	async signPnResendOtp(resendOtpPnSignES: IResendOtpESigningES) {
		let data = await signApiUnauthenticatedClient.post(
			authAndSignApiRoutes.sign.signPnResendOtp,
			resendOtpPnSignES
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong when trying to resend the otp')
	},

	async printPn(applicationId: number, t: TFunction) {
		const data = await cdaClient.post(
			cdaApiRoutes.printPn,
			{ applicationId: applicationId },
		)

		if (data.status === 200) return data.status
		throw new Error(t('ErrorMsg'))
	},

	async confirmApplication(applicationId: number) {
		let data = await cdaClient.post(
			cdaApiRoutes.confirmApplication,
			{ applicationId: applicationId },
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong confirming application.')
	},
		
	async confirmMultiCashApplication(applicationId: number) {
		let data = await cdaClient.post(
			cdaApiRoutes.confirmMultiCashApplication,
			{ applicationId: applicationId },
		)
		if (data.status === 200) {
			return data.status
		}
		throw new Error('Something went wrong confirming application.')
	},
}

export default pnServices
