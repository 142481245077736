import { ProductSettingTypes } from 'constants/enums/productTypes'
import { IFooter } from 'interfaces/i-footer'

export const footerValues: IFooter[] = [
	/* Always keep 'en' value first */
	{
		country: 'en',
		tfFullName: 'TF Bank',
		copyRight: 'TF Bank AB',
		links: {
			about: {
				title: 'AboutUs',
				link: 'https://www.tfbank.se/om-tf-bank/',
			},
		},
		address: {
			companyName: 'TF Bank AB',
			addressLine1: 'Box 947',
			zipCodeAndCity: '501 10, Borås',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'info@tfbank.se',
				},
			},
		],
	},
	{
		country: 'dk',
		tfFullName: 'TF Bank',
		copyRight: 'TF Bank AB, Danmark',
		links: {
			about: {
				title: 'AboutUs',
				link: 'https://tfbank.dk/om-tf-bank',
			},
		},
		address: {
			companyName: 'TF Bank AB',
			addressLine1: 'Box 947',
			zipCodeAndCity: '501 10, Borås',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'info@tfbank.dk',
					phoneNumber: '78 73 04 60',
				},
			},
		],
	},
	{
		country: 'ee',
		tfFullName: 'TF Bank',
		copyRight: 'TF Bank Kõik õigused kaitstud',
		links: {
			about: {
				title: 'AboutUs',
				link: 'https://tfbank.ee/kes-me-oleme/',
			},
		},
		address: {
			companyName: 'TF Bank AB (publ.) Eesti filiaal',
			addressLine1: 'PK 1201',
			addressLine2: 'Pallasti 28',
			zipCodeAndCity: '10001, Tallinn',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'info@tfbank.ee',
					phoneNumber: '607 5775',
				},
			},
		],
	},
	{
		country: 'es',
		tfFullName: 'TF Bank',
		copyRight: 'TF Bank AB, España',
		links: {
			about: {
				title: 'AboutUs',
				link: 'https://tfbank.es/sobre-tf-bank',
			},
		},
		address: {
			companyName: 'TF Bank AB',
			addressLine1: 'Box 947',
			zipCodeAndCity: '501 10, Borås',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'hola@tfbank.es',
					phoneNumber: '91 993 10 15',
				},
			},
		],
	},
	{
		country: 'fi',
		tfFullName: 'TF Bank AB, Suomen sivuliike (2594352-3)',
		copyRight: 'TF Bank AB, Ruotsi',
		links: {
			about: {
				title: 'AboutUs',
				link: 'https://www.tfbank.fi/tietoa-tf-bankista/',
			},
		},
		address: {
			companyName: 'TF Bank AB, Suomen sivuliike',
			addressLine1: 'Y-tunnus 2594352-3',
			addressLine2: 'PL 5600',
			zipCodeAndCity: '00002, Helsinki',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'info@tfbank.fi',
					phoneNumber: '0206 90591',
					phoneNumber2: '0206 90681',
				},
			},
		],
	},
	{
		country: 'de',
		tfFullName: 'TF Bank AB',
		copyRight: 'TF Bank AB',
		address: {
			companyName: 'TF Bank AB',
			addressLine1: 'Postfach 10 02 26',
			zipCodeAndCity: '20001, Hamburg',
			country: 'Deutschland',
		},
		links: {
			legal: {
				title: 'LegalInformation',
				link: 'https://tfbank.de/impressum',
			},
			dataProtection: {
				title: 'DataProtection',
				link: 'https://tfbank.de/datenschutz',
			},
			faq: {
				title: 'Service',
				link: 'https://tfbank.de/service',
			},
			cookie: {
				title: 'Cookies',
				link: 'https://tfbank.de/cookies',
			},
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.Card,
				contactInformation: {
					email: 'service@tfbank.de',
					openingHours: '8:00 - 18:00',
				},
				bankInformation: {
					iban: 'DE77512202000070508402',
					bic: 'ESSEDEFFXXX',
				},
			},
			{
				productType: ProductSettingTypes.Deposit,
				contactInformation: {
					email: 'tagesgeld@tfbank.de',
					openingHours: '8:00 - 18:00',
				},
				bankInformation: {
					iban: 'DE06202208000000024118',
					bic: 'SXPYDEHH',
				},
			},
		],
	},
	{
		country: 'no',
		tfFullName: 'TF Bank Norge',
		copyRight: 'TF Bank AB',

		links: {
			cookie: {
				title: 'Cookies',
				link: 'https://www.tfbank.no/personvernerklaring/',
			},
			faq: {
				title: 'FAQ',
				link: 'https://tfbank.no/hjelp',
			},
		},
		address: {
			companyName: 'TF Bank Norge',
			addressLine1: 'Postboks 956 Sentrum',
			zipCodeAndCity: '5808, Bergen',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.Card,
				contactInformation: {
					email: 'kort@tfbank.no',
					phoneNumber: '56 99 92 00',
					openingHours: '09:00 - 18:00',
				},
			},
			{
				productType: ProductSettingTypes.Deposit,
				contactInformation: {
					email: 'innskudd@tfbank.no',
					phoneNumber: '56 99 92 00',
					openingHours: '09:00 - 18:00',
				},
			},
			{
				productType: ProductSettingTypes.Loan,
				contactInformation: {
					email: 'post@tfbank.no',
				},
			},
		],
	},
	{
		country: 'se',
		tfFullName: 'TF Bank AB, Sverige',
		copyRight: 'TF Bank AB, Sverige',
		links: {
			cookie: {
				title: 'AboutUs',
				link: 'https://www.tfbank.se/om-tf-bank/',
			},
			faq: {
				title: 'Cookies',
				link: 'https://tfbank.se/om-tf-bank/cookies-och-sakerhet',
			},
		},
		address: {
			companyName: 'TF Bank AB',
			addressLine1: 'Box 947',
			zipCodeAndCity: '501 10, Borås',
		},
		productSpecific: [
			{
				productType: ProductSettingTypes.All,
				contactInformation: {
					email: 'info@tfbank.se',
					phoneNumber: '033-722 35 00',
				},
			},
		],
	},
]
