import React from 'react'

import { HeaderType } from 'constants/enums/headerTypes'
import { CountryTypes } from 'constants/enums/countryTypes'
import LoanOfferHeaderTopUp from 'components/application-headers/loan-offer-headers/loan-offer-header-topup/LoanOfferHeaderTopUp'
import LoanOfferHeader from 'components/application-headers/loan-offer-headers/loan-offer-header/LoanOfferHeader'
import { LoanApplicationStepsDk } from '../application-multistep-values/msvDenmark'
import { LoanApplicationStepsEe } from '../application-multistep-values/msvEstonia'
import LoanApplicationHeader from 'components/application-headers/loan-application-header/LoanApplicationHeader'
import { LoanApplicationStepsEs } from '../application-multistep-values/msvSpain'
import { LoanApplicationStepsFi } from '../application-multistep-values/msvFinland'
import { LoanApplicationStepsNo } from '../application-multistep-values/msvNorway'
import { LoanApplicationStepsSe } from '../application-multistep-values/msvSweden'

export interface IApplicationConfigProps {
	country: string
	header: IHeader[]
	multistep: JSX.Element
}

interface IHeader {
	headerContent: JSX.Element
	headerType: HeaderType
}

export const applicationContextConfigLoans: IApplicationConfigProps[] = [
	{
		country: CountryTypes.dk,
		header: [
			{
				headerType: HeaderType.LoanOfferTopUp,
				headerContent: <LoanOfferHeaderTopUp />,
			},
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <LoanOfferHeader />,
			},
		],
		multistep: <LoanApplicationStepsDk />,
	},
	{
		country: CountryTypes.ee,
		header: [
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <></>,
			},
		],
		multistep: <LoanApplicationStepsEe />,
	},
	{
		country: CountryTypes.es,
		header: [
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <LoanOfferHeader />,
			},
			{
				headerType: HeaderType.LoanApplication,
				headerContent: <LoanApplicationHeader />,
			},
		],
		multistep: <LoanApplicationStepsEs />,
	},
	{
		country: CountryTypes.fi,
		header: [
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <LoanOfferHeader />,
			},
			{
				headerType: HeaderType.LoanOfferTopUp,
				headerContent: <LoanOfferHeaderTopUp />,
			},
		],
		multistep: <LoanApplicationStepsFi />,
	},
	{
		country: CountryTypes.no,
		header: [
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <LoanOfferHeader />,
			},
		],
		multistep: <LoanApplicationStepsNo />,
	},
	{
		country: CountryTypes.se,
		header: [
			{
				headerType: HeaderType.LoanOfferTopUp,
				headerContent: <LoanOfferHeaderTopUp />,
			},
			{
				headerType: HeaderType.LoanOffer,
				headerContent: <LoanOfferHeader />,
			},
		],
		multistep: <LoanApplicationStepsSe />,
	},
]
